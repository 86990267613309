'use strict'

###*
 # @ngdoc service
 # @name mundoMap.factory:MundoMap

 # @description

###
angular
  .module 'mundoMap'
  .factory 'MundoMap', [
    '$window',
    '$log',
    'debounce',
    '$timeout',
    'mundoConfiguration',
    'Restangular',
    '_',
    'DisplayLabel',
    '$http',
    ($window, $log, debounce, $timeout, mundoConfiguration, Restangular, _, DisplayLabel, $http) ->
      ol = $window.ol

      # Define Belgian Lambert 72 (EPSG:31370) projection
      proj4.defs "EPSG:31370", "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90" +
        " +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl" +
        " +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs"

      MundoMapBase = {}
      MundoMapBase.GeoJSON = new ol.format.GeoJSON

      MundoMapBase.styleCache = {}
      MundoMapBase.styleDefaults = (feature) ->
        #/** @type {Object.<ol.geom.GeometryType, Array.<ol.style.Style>>} */
        style = null
        bgColor = '#F9F9F9'
        textColor = '#0A94D6'
        iconType = 'police-vehicle'
        iconColor = '#CCCCCC'

        activeLabelDisplay = DisplayLabel.getActiveKey()
        opacity= 0.85
        if feature.get('inactive') is true
          opacity= 0.4

        if not style
          style = [
              new ol.style.Style
                image: new ol.style.Icon
                  #/** @type {olx.style.IconOptions} */
                  anchor: [0.5, 1]
                  anchorXUnits: 'fraction'
                  anchorYUnits: 'fraction'
                  opacity: opacity
                  src: "/images/map-rectangle/default.png"
                  color: bgColor
                text: new ol.style.Text
                  text: feature.get("label")
                  scale: 1.25
                  offsetY: -20
                  offsetX: 10
                  fill: new ol.style.Fill
                    color: textColor
            ,
              new ol.style.Style
                image: new ol.style.Icon
                  #/** @type {olx.style.IconOptions} */
                  anchor: [2.45, 1.375]
                  anchorXUnits: 'fraction'
                  anchorYUnits: 'fraction'
                  opacity: 0.85
                  src: "/images/map-vehicle/#{iconType}.svg"
                  color: iconColor
          ]

        return style

      MundoMapBase.clusterStyleCache = {}
      MundoMapBase.clusterStyleDefaults = (feature, resolution) ->
        size = feature.get('features').length
        style = null

        if size == 1
          feature = feature.get('features')[0]
          style = feature.get('_cachedStyle')

          if not style
            style = MundoMapBase.styleDefaults feature
            feature.set('_cachedStyle', style)
        else
          style = MundoMapBase.clusterStyleCache[size]

          if not style
            style = [
              new ol.style.Style
                image: new ol.style.Circle
                  radius: 15
                  stroke: new ol.style.Stroke
                    color: '#FFF'
                  fill: new ol.style.Fill
                    color: '#3399CC'
                text: new ol.style.Text
                  text: size.toString()
                  scale: 1.5
                  fill: new ol.style.Fill
                    color: '#FFF'
            ]

            MundoMapBase.clusterStyleCache[size] = style

        return style

      MundoMapBase.layerDefaults =
        _layerType: 'Tile'
        _searchable: false
        _zoomable: false
        preload: 3
        visible: false

      newDefaultLayers = [
        _layerId: 'osm_base'
        _weight: 60
        title: 'OSM BE'
        type: 'base'
        visible: true
        source: [
          'OSM'
          url: "https://tile.openstreetmap.be/osmbe/{z}/{x}/{y}.png"
          attributions: [ ol.source.OSM.ATTRIBUTION, "Tiles courtesy of <a href=\"https://geo6.be/\">GEO-6</a>" ]
          maxZoom: 18
        ]
      ,
        _layerId: 'osm'
        _weight: 60
        title: 'OSM'
        type: 'base'
        source: ['OSM']
      ,
        _layerId: 'stamen_toner'
        _weight: 60
        title: 'Stamen (Toner)'
        type: 'base'
        source: [
          'Stamen',
            layer: 'toner-lite'
        ]
      ,
        _layerId: 'bing_aerial_with_labels'
        _weight: 60
        title: 'Bing (Aerial + Labels)'
        type: 'base'
        source: [
          'BingMaps'
            imagerySet: 'AerialWithLabels'
            maxZoom: 19
        ]
      ,
      #   _layerType: 'Vector'
      #   _layerId: 'trips'
      #   _weight: 50
      #   _clusterable: false
      #   _searchable: false
      #   _zoomable: true
      #   _updateWhileMoving: true
      #   title: 'Trips (Base)'
      #   visible: true
      #   wrapX: false
      #   source: [
      #     'Vector'
      #       features: []
      #   ]
      #   style: null
      # ,
        _layerType: 'Vector'
        _layerId: 'markers'
        _weight: 50
        _clusterable: true
        _searchable: false
        _zoomable: true
        _updateWhileMoving: true
        title: 'Markers (Base)'
        visible: true
        source: [
          'Vector'
            features: []
        ]
        style: null
      ,
        _layerType: 'Heatmap'
        _layerId: 'heatmap'
        _weight: 50
        _clusterable: false
        _searchable: false
        _zoomable: true
        _updateWhileMoving: true
        title: 'Heatmap'
        visible: false
        source: [
          'Vector'
            features: []
        ]
      ]

      lpaDefaultLayers = [
        _layerId: 'osm_base'
        _weight: 60
        title: 'OSM'
        type: 'base'
        visible: true
        source: ['OSM']
      ,
        # _layerId: 'google'
        # _weight: 60
        # title: 'Google Maps'
        # type: 'base'
        # source: [
        #   'XYZ',
        #   attributions: [new ol.Attribution { html: '<a href=""></a>' }],
        #   url: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
        # ]
      # ,
        # _layerId: 'google satellite'
        # _weight: 60
        # title: 'Google Maps (Satellite)'
        # type: 'base'
        # source: [
        #   'XYZ',
        #   attributions: [new ol.Attribution { html: '<a href=""></a>' }],
        #   url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
        # ]
      # ,
        # _layerId: 'mapquest_osm'
        # title: 'MapQuest (OSM)'
        # type: 'base'
        # visible: false
        # source: [
        #   'MapQuest',
        #     layer: 'osm'
        # ]
      # ,
        _layerId: 'stamen_toner'
        _weight: 60
        title: 'Stamen (Toner)'
        type: 'base'
        source: [
          'Stamen',
            layer: 'toner-lite'
        ]
      ,
        # _layerId: 'mapquest_sat'
        # title: 'MapQuest (Satellite)'
        # type: 'base'
        # source: [
        #   'MapQuest',
        #     layer: 'sat'
        # ]
      # ,
        # _layerId: 'mapquest_hybrid'
        # _layerType: 'Group'
        # title: 'MapQuest (Aerial hybrid)'
        # type: 'base'
        # layers: [
        #     source: [
        #       'MapQuest'
        #         layer: 'sat'
        #     ]
          # ,
        #     source: [
        #       'MapQuest'
        #         layer: 'hyb'
        #     ]
        # ]
      # ,
        # title: 'ArcGIS (REST)'
        # type: 'base'
        # source: [
        #   'TileArcGISRest'
        #     url: 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/' +
        #       'basemap_stadsplan_v6/MapServer'
        # ]
      # ,
        _layerId: 'arcgis_xyz_base'
        _weight: 60
        title: 'ArcGIS (XYZ)'
        type: 'base'
        source: [
          'XYZ'
            url: 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/' +
              'basemap_stadsplan_v6/MapServer/tile/{z}/{y}/{x}'
        ]
      ,
        _layerId: 'arcgis_rest_antwerp_base'
        _weight: 60
        title: 'Antwerp Geodata (ArcGIS, REST)'
        type: 'base'
        source: [
          'TileArcGISRest'
            projection: 'EPSG:31370'
            url: 'https://geodata.antwerpen.be/arcgissql/rest/services/' +
              'P_Publiek/P_basemap/MapServer'
        ]
      ,
        _layerId: 'bing_road'
        _weight: 60
        title: 'Bing (Road)'
        type: 'base'
        source: [
          'BingMaps'
            imagerySet: 'Road'
        ]
      ,
        _layerId: 'bing_aerial_with_labels'
        _weight: 60
        title: 'Bing (Aerial + Labels)'
        type: 'base'
        source: [
          'BingMaps'
            imagerySet: 'AerialWithLabels'
        ]
      ,
      #   _layerType: 'Vector'
      #   _layerId: 'trips'
      #   _weight: 50
      #   _clusterable: false
      #   _searchable: false
      #   _zoomable: true
      #   _updateWhileMoving: true
      #   title: 'Trips (Base)'
      #   visible: true
      #   wrapX: false
      #   source: [
      #     'Vector'
      #       features: []
      #   ]
      #   style: null
      # ,
        _layerType: 'Vector'
        _layerId: 'markers'
        _weight: 50
        _clusterable: true
        _searchable: false
        _zoomable: true
        _updateWhileMoving: true
        title: 'Markers (Base)'
        visible: true
        source: [
          'Vector'
            features: []
        ]
        style: null
      ,
        _layerType: 'Heatmap'
        _layerId: 'heatmap'
        _weight: 50
        _clusterable: false
        _searchable: false
        _zoomable: true
        _updateWhileMoving: true
        title: 'Heatmap'
        visible: false
        source: [
          'Vector'
            features: []
        ]
      ]

      if mundoConfiguration.oauth.baseUrl.includes("lpa")
        defaultLayers = lpaDefaultLayers
      else
        defaultLayers = newDefaultLayers
      

      MundoMapBase.defaults = 
        layers: defaultLayers
        view:
          center: [4.402616, 51.216238]
          zoom: 12
          maxZoom: 20
        controls: [
          ['Zoom']
          ['ZoomSlider']
          ['ScaleLine']
          ['LayerSwitcher']
          ['FullScreen']
        ]
        follow:
          objects: {}
          enabled: true
          speedZoom: true
          zoomLevel: 18
        fillScreen: true
        search:
          enabled: true
          events:
            onSearchQueryExecute: () ->
            onTagSearchQueryExecute: () ->
            onAddressSearchQueryExecute: () ->
            onSearchQueryExecuted: (map, query, results) ->
              map._mundoMapOptions.follow.objects = results

              if results.length
                map
                  ._mundoMap
                  .panToFeatures map, results
          tagRules: [
            (t) -> /^\s*[^\s]+\s*$/.test t
          ]
        BingMapsApiKey: null

      MundoMapBase.createLayerFromConfig = (mapOptions, layerOptions) ->
        layer = null
        layerOptions = angular.merge {}, @layerDefaults, layerOptions

        if layerOptions._layerType == 'Group'
          layers = []

          for subLayerOptions in layerOptions.layers
            layers.push (@createLayerFromConfig mapOptions, subLayerOptions)

          layerOptions.layers = layers
        else
          if angular.isFunction layerOptions.source
            layerOptions.source = layerOptions.source.apply @, []
          else
            if layerOptions.source[0] == 'BingMaps'
              layerOptions.source[1] ?= {}
              layerOptions.source[1].key ?= mapOptions.BingMapsApiKey

              if not layerOptions.source[1].key?
                $log.warn 'Map: Could not create BingMaps layer due to missing API key'
                return null

            # Exception for layers from files
            if layerOptions.source[1]? and layerOptions.source[1].format? and layerOptions.source[1].format == 'GeoJSON'
              layerOptions.source = new ol.source.Vector
                format: new ol.format.GeoJSON()
                url: layerOptions.source[1].url
            else
              layerOptions.source = new ol.source[layerOptions.source[0]] (layerOptions.source[1] or {})

          if layerOptions._layerType == 'Vector'
            layerOptions.style ?= @styleDefaults

            if layerOptions._clusterable
              layerOptions.style = @clusterStyleDefaults
              layerOptions.source = new ol.source.Cluster
                source: layerOptions.source
                distance: 10

            if layerOptions._updateWhileMoving
              layerOptions.updateWhileAnimating = true
              layerOptions.updateWhileInteracting = true


            #EsriJSON needs to be done like this
            if layerOptions._format == "EsriJSON"
              esriSource = new ol.source.Vector
                strategy: ol.loadingstrategy.all
                loader: (extent, resolution, projection) ->
                  $http(
                    method: layerOptions._http.method
                    url: layerOptions._http.url
                    withCredentials: layerOptions._http.withCredentials
                    headers:
                      layerOptions._http.headers
                    params:
                      layerOptions._http.params
                  ).then (data, status, headers, config) ->
                    features = MundoMapBase.getGeometryFromEsriJSON(data.data)
                    esriSource.addFeatures features
                  , (data, status, headers) ->
                    $log.warn "Stuff doesn't work"

              layerOptions.source = esriSource
        layer = new ol.layer[layerOptions._layerType] layerOptions

        return layer

      MundoMapBase.getGeometryFromGeoJSON = (geoJson) ->
        geometry = @GeoJSON
          .readGeometry geoJson,
            # dataProjection: 'EPSG:4326'
            featureProjection: 'EPSG:3857'

      MundoMapBase.getGeometryFromEsriJSON = (EsriJSON) ->
        EsriJSONFormat = new ol.format.EsriJSON()
        geometry = EsriJSONFormat.readFeatures EsriJSON,
          featureProjection: 'EPSG:3857'
        geometry

      MundoMapBase.getLayersByPropertyValue = (map, property, value) ->
        results = map
          .getLayers()
          .getArray()
          .filter (v) ->
            v.get(property) == value

        return results

      MundoMapBase.getLayerById = (map, layerId) ->
        results = @getLayersByPropertyValue map, '_layerId', layerId

        return results[0] or null

      MundoMapBase.getVisibleLayerIds = (map) ->
        visibleLayerIds = []
        angular.forEach map.getLayers(), (mapLayer) ->
          if mapLayer.getVisible()
            visibleLayerIds.push (mapLayer.get '_layerId')
        return visibleLayerIds

      MundoMapBase.setVisibleLayers = (map, layerIds) ->
        angular.forEach map.getLayers(), (mapLayer) ->
          if layerIds.indexOf(mapLayer.get '_layerId') > -1
            mapLayer.setVisible true
          else
            mapLayer.setVisible false

      MundoMapBase.getLayerSource = (layer) ->
        if layer.get('_clusterable') == true
          return layer.getSource().getSource()

        return layer.getSource()

      MundoMapBase.fromMetresToPixels = (map, metres) ->
        view = map.getView()
        resolution = view.getResolution()
        pixels = metres / resolution

        return pixels

      MundoMapBase.zoomAroundPoint = (map, point, metres = 50) ->
        coords = []

        for x in [[-1, -1], [-1, 1], [1, 1], [1, -1]]
          offsetPoint = point.clone()
          offsetPoint.translate(metres * x[0], metres * x[1])
          coords.push offsetPoint.getCoordinates()

        # Build an extent which contains the given coords
        extent = ol.extent.boundingExtent coords

        # Zoom map to extent
        @zoomToExtent map, extent

      MundoMapBase.zoomToExtent = (map, extent, padding = 100) ->
        map
          .getView()
          .fit extent,
            padding: [padding, padding, padding, padding]
            constrainResolution: true

      MundoMapBase.panToFeatures = (map, features) ->
        if not features.length
          return

        if features.length == 1
          # When there is only item. Just pan and zoom to 15 or something like that
          feature = features[0]
          map.getView().fit feature.getGeometry().getExtent(),
            maxZoom: 15
            constrainResolution: true
        else
          extent = ol.extent.createEmpty()

          for feature in features
            ol.extent.extend extent, feature.getGeometry().getExtent()

          @zoomToExtent map, extent

      MundoMapBase.speedZoom = (map, feature) ->
        if not map._mundoMapOptions.follow.speedZoom.enabled
          returns

      MundoMapBase.autoFit = (map) ->
        if not map._mundoMapOptions.follow.enabled
          return

        layers = @getLayersByPropertyValue map, '_zoomable', true

        if not layers.length
          return

        extent = ol.extent.createEmpty()

        for layer in layers
          ol.extent.extend extent, layer.getSource().getExtent()

        if extent[0] == Infinity
          return

        @zoomToExtent map, extent

      MundoMapBase.executeTagSearchQuery = (map, query) ->
        map._mundoMapOptions.search.events.onTagSearchQueryExecute map, query
        $log.debug 'Map: Executing tag search => query: ', query

        results = @getLayersByPropertyValue map, '_searchable', true
          .map (layer) =>
            return @getLayerSource layer
              .getFeatures()
              .filter (feature) ->
                if feature.get('_tags')?
                  return feature.get('_tags')
                    .filter (tag) ->
                      tag = tag.toLowerCase()
                      query = query.toLowerCase()
                      tag.indexOf(query) isnt -1
                    .length

                return false

        results = _.union.apply _.union, results
        map._mundoMapOptions.search.events.onSearchQueryExecuted map, query, results
        $log.debug "Map: Executed tag search => query: #{query}, results: ", results

        return results

      MundoMapBase.executeAddressSearchQuery = (map, query) ->
        map._mundoMapOptions.search.events.onAddressSearchQueryExecute map, query
        $log.debug 'Map: Executing address search => query: ', query

        # @TODO Make a Geocoder service that uses Nominatim, JSONP, some other magic
        # to determine coordinates for a given address string and returns
        # a feature and/or geometry object
        results = []

        map._mundoMapOptions.search.events.onSearchQueryExecuted map, query, results
        $log.debug "Map: Executed address search => query: #{query}, results: ", results

      MundoMapBase.executeSearchQuery = (map, query) ->
        query = query.trim()

        if query
          map._mundoMapOptions.search.events.onSearchQueryExecute map, query

          for rule in map._mundoMapOptions.search.tagRules
            if rule query
              return @executeTagSearchQuery map, query

          @executeAddressSearchQuery map, query
        else
          map._mundoMapOptions.follow.objects = []
          @autoFit map

      MundoMapBase.setZoomLevel = (map, zoomLevel) ->

        zoom = ol.animation.zoom
          duration: 500
          resolution: map.getView().resolution()

        map.beforeRender(zoom)

        map._options.follow.zoomLevel = zoomLevel
        map.getView().setZoom zoomLevel

      MundoMapBase.setSpeedZoom = (map, state) ->
        map._options.follow.speedZoom = state

      MundoMapBase.setFollow = (map, state) ->
        map._options.follow.enabled = state

      MundoMapBase.mapSpeedToZoomLevel = (speed) ->
        if speed >= 0 and speed <= 10
          19
        else if speed <= 30
          18
        else if speed <= 50
          17
        else if speed <= 90
          16
        else if speed <= 150
          15
        else
          14

      MundoMapBase.updateMapView = (map) ->
        if map._options.follow.enabled
          if Object.keys(map._options.follow.objects).length
            @panToFeatures map, map._options.follow.objects
            if map._options.follow.speedZoom and Object.keys(map._options.follow.objects).length is 1
              @autoFit map
              angular.forEach map._options.follow.objects, (status) =>
                if status.location.gps.speed
                  @setZoomLevel map, @mapSpeedToZoomLevel status.location.gps.speed
                else
                  @setZoomLevel map, map._options.follow.zoomLevel
          else
            @autoFit map

      MundoMapBase.getMarkerPath = (objectId) ->
        "#{Restangular.configuration.baseUrl}/open/units/markers/#{objectId}"

      MundoMapBase.cameraStyle = (feature) ->
        style = null
        bgColor = '#0A94D6'
        textColor = '#FFFFFF'
        iconColor = '#00368b'

        if not style
          style = [
            new ol.style.Style
              image: new ol.style.Icon
                #/** @type {olx.style.IconOptions} */
                anchor: [0.5, 0.5]
                anchorXUnits: 'fraction'
                anchorYUnits: 'fraction'
                opacity: 0.85
                size: [400,400]
                imgSize: [400,400]
                scale: .04
                src: "/images/map-anpr/camera.png"
                color: iconColor
          ]

        return style

      MundoMapBase.createInstance = (target, options = {}) ->
        defaultOptions = angular.merge {}, @defaults

        if options.layers?
          defaultOptions.layers = defaultOptions.layers.concat options.layers
          delete options.layers

        # If controls are given overwrite defaults
        if options.controls?
          defaultOptions.controls = options.controls
          delete options.controls

        options = angular.merge {}, defaultOptions, options
        controls = []
        layers = []
        view = null

        options.view.center = ol.proj.fromLonLat options.view.center
        view = new ol.View options.view

        options.layers.sort (a, b) ->
            aWeight = if a._weight? then a._weight else 50
            bWeight = if b._weight? then b._weight else 50
            return bWeight - aWeight

        for layer in options.layers
          result = (@createLayerFromConfig options, layer)

          if result?
            layers.push result

        for control in options.controls
          if ol.control[control[0]]?
            controls.push new ol.control[control[0]]

        map = new ol.Map
          target: target
          layers: layers
          controls: controls
          renderer: 'canvas'
          view: view
          moveTolerance: 5
          loadTilesWhileAnimating: true
          loadTilesWhileInteracting: true

        map._options = options

        # Somehow this is neccesary for rendering the map in fullscreen
        $timeout ->
          map.updateSize()

        map._mundoMap = @
        map._mundoMapOptions = options

        if map._options.follow.enabled
          for layer in @getLayersByPropertyValue map, '_zoomable', true
            @getLayerSource layer
              .on 'addfeature', (e) =>
                $timeout () =>
                  @updateMapView(map)
                , 500

        handleSearchEvent = debounce 500, (event) =>
          query = event.target.value
          @executeSearchQuery map, query

        if map._options.search.enabled
          angular.element "##{target}"
            .addClass 'mundo-map'
            .append "<input class='map-search' type='text'>"
            .find '.map-search'
            .on 'keyup', handleSearchEvent
            .on 'paste', handleSearchEvent
        $log.debug "Map: Created a map instance => target: #{target}, options: ", options

        return map

      # Inject API keys
      if mundoConfiguration.map? && mundoConfiguration.map.BingMaps? &&
      mundoConfiguration.map.BingMaps.apiKey?
        MundoMapBase.defaults.BingMapsApiKey = mundoConfiguration.map.BingMaps.apiKey

      MundoMapBase
  ]
